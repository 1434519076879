<template>
  <div>
    <v-form>
      <v-row>
        <v-col>
          <h2 class="text-left text-xl font-weight-semibold label">Device ID: {{ deviceId }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2 class="text-left text-xl font-weight-semibold label">SSD Serial: {{ hardwareId }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4 class="text-left text-xl font-weight-semibold label">Case ID</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="caseId" label="Case ID" hide-details outlined> </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4 class="text-left text-xl font-weight-semibold label">Sim ID</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="simId" label="Sim ID" hide-details outlined> </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center justify-center" style="padding-top: 50px">
          <v-btn color="primary" x-large :disabled="!hardwareValid" v-bind="attrs" v-on="on">
            Add hardware to device
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Provision device {{ deviceId }} </v-card-title>
        <v-card-text>
          <ul>
            <li>case ID: {{ caseId }}</li>
            <li>sim ID: {{ simId }}</li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> No </v-btn>
          <v-btn color="primary" text @click="addHardware"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store"

export default {
  name: "Hardware",
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    hardwareId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      caseId: "",
      dialog: false,
      simId: "",
    }
  },
  computed: {
    hardwareValid() {
      return this.caseId !== "" && this.simId !== ""
    },
  },
  methods: {
    async addHardware() {
      this.dialog = false
      await store.dispatch("AddHardware", {
        deviceUUID: this.deviceId,
        ssdSerial: this.hardwareId,
        caseId: this.caseId,
        simId: this.simId,
      })
      window.location.reload()
    },
  },
}
</script>
