<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="text-left text-xl font-weight-semibold label">Device ID: {{ deviceId }}</h2>
        <h2 class="text-left text-xl font-weight-semibold label">Vehicle ID: {{ presetVehicleID }}</h2>
      </v-col>
    </v-row>
    <div class="d-flex align-center justify-center">
      <v-btn color="primary" x-large @click="decommissionDevice"> Decommission </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import store from "@/store"

export default {
  name: "Decommission",
  props: {
    deviceId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({ vehicles: state => state.gtfs.vehicles }),
    presetVehicleID() {
      let vID = 0
      this.vehicles.forEach(v => {
        if (v.status.device_id === this.deviceId) {
          vID = v.vehicle_id
        }
      })

      return vID
    },
  },
  methods: {
    async decommissionDevice() {
      await store.dispatch("DecommissionDevice", this.deviceId)
      window.location.reload()
    },
  },
}
</script>
