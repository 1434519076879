<template>
  <div id="next-stop-provision">
    <div class="page-title px-4">
      <h2>
        <span class="me-2">Device Provisioning</span>
      </h2>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <div>
      <v-card flat>
        <v-card-text>
          <v-container fluid>
            <div v-if="deviceStatusFetched" class="tts-standard-segments">
              <Hardware v-if="deviceExists === false" :device-id="deviceId" :hardware-id="hardwareId" />
              <Provision v-else-if="deviceProvisioned === false" :device-id="deviceId" />
              <Decommission v-else :device-id="deviceId" />
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <br />
    <div>
      {{ releaseVersion }}
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "@vue/composition-api/dist/vue-composition-api"
import Provision from "./components/Provision.vue"
import Decommission from "./components/Decommission.vue"
import Hardware from "./components/Hardware.vue"
import store from "@/store"

export default {
  name: "Landing",
  metaInfo() {
    return {
      title: "Provisioning",
    }
  },
  components: {
    Provision,
    Decommission,
    Hardware,
  },
  data() {
    return {
      deviceExists: null,
      deviceProvisioned: null,
      releaseVersion: null,
    }
  },
  computed: {
    deviceStatusFetched() {
      return this.deviceExists !== null && this.deviceProvisioned !== null
    },
  },
  setup() {
    const curr = getCurrentInstance()
    const deviceId = curr.proxy.$route.params.balenaid
    const hardwareId = curr.proxy.$route.params.hardwareid

    return {
      deviceId,
      hardwareId,
    }
  },
  async created() {
    this.deviceExists = await store.dispatch("GetHardware", this.deviceId)
    this.deviceProvisioned = await store.dispatch("IsProvisioned", this.deviceId)
    this.releaseVersion = await store.dispatch("GetReleaseVersion", this.deviceId)
  },
}
</script>
