<template>
  <div>
    <v-form>
      <v-row>
        <v-col>
          <h2 class="text-left text-xl font-weight-semibold label">Device ID: {{ deviceId }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4 class="text-left text-xl font-weight-semibold label">Can Baud Rate</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="can"
            :items="canOptions"
            label="CAN baud rate"
            item-text="name"
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" medium :disabled="baudSelected" @click="selectBaudRate"> Select baud rate </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" medium :disabled="!baudSelected" style="float: right" @click="removeBaudRate">
            Remove baud rate
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="baudSelected">
        <div style="margin: 50px 0" />
        <v-row>
          <v-col>
            <h4 class="text-left text-xl font-weight-semibold label">Vehicle ID</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="vehicleID" label="Vehicle ID" hide-details outlined> </v-text-field>
          </v-col>
        </v-row>
        <hr style="margin: 50px 0" />
        <v-row>
          <v-col>
            <h4 class="text-left text-xl font-weight-semibold label">Provision checklist</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="gps" :label="'GPS working'"></v-checkbox>
            <v-checkbox v-model="etm" :label="'ETM working'"></v-checkbox>
            <v-checkbox v-model="doors" :label="'Door status changes with open/close'"></v-checkbox>
            <v-checkbox v-model="balena" :label="'Balena set-up and healthy'"></v-checkbox>
            <v-checkbox v-model="network" :label="'Correct network'"></v-checkbox>
            <v-checkbox v-model="audio" :label="'Audio working'"></v-checkbox>
            <v-checkbox v-model="screens" :label="'All screens working'"></v-checkbox>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <div v-if="baudSelected">
      <div class="d-flex align-center justify-center">
        <v-btn color="primary" x-large :disabled="!provisionValid" @click="provisionDevice"> Provision </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store"

export default {
  name: "Provision",
  props: {
    deviceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      canOptions: [
        { value: 0, name: "0: 1M" },
        { value: 1, name: "1: 800K" },
        { value: 2, name: "2: 500K" },
        { value: 3, name: "3: 250K" },
        { value: 4, name: "4: 200K" },
        { value: 5, name: "5: 125K" },
      ],
      vehicleID: "",
      gps: false,
      etm: false,
      can: null,
      audio: false,
      balena: false,
      screens: false,
      network: false,
      baudSelected: false,
      doors: false,
    }
  },
  computed: {
    provisionValid() {
      return (
        this.vehicleID !== "" &&
        this.gps === true &&
        this.etm === true &&
        this.baudSelected === true &&
        this.audio === true &&
        this.balena === true &&
        this.screens === true &&
        this.network === true &&
        this.doors === true
      )
    },
  },
  async created() {
    this.can = await store.dispatch("GetBaudRate", this.deviceId)
    this.baudSelected = this.can !== null
  },
  methods: {
    async provisionDevice() {
      await store.dispatch("ProvisionDevice", { deviceUUID: this.deviceId, vehicleId: this.vehicleID })
      window.location.reload()
    },
    async selectBaudRate() {
      if (this.can === null) {
        store.commit("SET_SNACKBAR", { text: "A baud rate must be selected.", color: "error" })

        return
      }
      this.baudSelected = await store.dispatch("AddBaudRate", { deviceUUID: this.deviceId, baudRate: this.can })
    },
    async removeBaudRate() {
      if (this.can === null) {
        store.commit("SET_SNACKBAR", { text: "A baud rate must be selected.", color: "error" })

        return
      }

      const removedBaudRate = await store.dispatch("RemoveBaudRate", this.deviceId)

      this.baudSelected = !removedBaudRate
      if (this.baudSelected) {
        this.can = null
      }
    },
  },
}
</script>
